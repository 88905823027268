import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import './style.scss';
import classnames from 'classnames';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import Banner from '@/components/atoms/banner';
import t from '@/lib/i18n';
import PageContext from '@/lib/providers/pageContext';

export type LayoutProps = {
  className?: string;
  header?: JSX.Element | null;
  content: JSX.Element;
  footer?: JSX.Element | null;
  footerBanner?: JSX.Element | null;
  pageView?: string;
  pageViewData?: any;
  referer?: string;
};

const Layout = ({
  className,
  header,
  footer,
  footerBanner,
  content,
  pageView,
  pageViewData,
  referer
}: LayoutProps) => {
  const pv = usePageView();
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (pageView) {
      const { ref } = usePdv();

      pv.send(pageView, {
        ...pageViewData,
        pdvRef: ref
      });
    }
  }, [pv, pageView, pageViewData]);

  useEffect(() => {
    if (window.__NEXT_DATA__.isPreview) {
      setIsPreview(true);
    }
  }, []);

  return (
    <PageContext.Provider value={{ referer, pageView }}>
      <div className={classnames('app', className)}>
        {isPreview && (
          <Banner
            className="preview-banner"
            classNameMessage="preview-banner__message"
            message={t('preview.banner.message')}
          >
            <Link href="/api/cms/exit-preview">
              <a className="preview-banner__exit">{t('preview.banner.exit')}</a>
            </Link>
          </Banner>
        )}
        {header && <header className="app__header">{header}</header>}
        <main className="app__main">{content}</main>
        {footer && <footer className="app__footer">{footer}</footer>}
        {footerBanner && (
          <div className="app__footer-banner">{footerBanner}</div>
        )}
      </div>
    </PageContext.Provider>
  );
};

export default Layout;
