import React, { useEffect } from 'react';
import ErrorTemplate from '@/components/templates/error';
import usePageView from '@/lib/hooks/usePageView';

const Error = () => {
  const pageView = usePageView();

  useEffect(() => {
    pageView.send('error', {
      statusCode: 404
    });
  }, [pageView]);

  return <ErrorTemplate statusCode={404} />;
};

export default Error;
